import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Modal,
  Row,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import axios from "axios";
import "./centeredModal.css";
import { CirclesWithBar } from "react-loader-spinner";

const MyVerticallyCenteredModal = (props) => {
  const { video, onHide, fetchVideos } = props;
  const [updatedAnnotation, setUpdatedAnnotation] = useState("");
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setUpdatedAnnotation(video.updated_annotation || "");
  }, [video]);

  const handleUpdate = async () => {
    if (window.confirm("Are you sure you want to modify the annotation?")) {
      const startTime = Date.now();
      setLoading(true);
      const apiEndpoint = `${apiUrl}/upload_video`;
      const data = new FormData();

      data.append("model_id", video.id);
      data.append("generated_annotation", video.annotation);
      data.append("sign_language", video.sign_language);
      data.append("domain", video.domain);
      data.append("model", video.model);
      data.append("modified_annotation", updatedAnnotation);
      data.append("file_name", video.file_name);

      // for (const value of data.values()) {
      //   console.log("formdata values",value);
      // }

      try {
        console.log("Start axios post");
        const axiosPostStartTime = Date.now();
        await axios.post(apiEndpoint, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const axiosPostEndTime = Date.now();
        console.log(
          `Time taken for axios post: ${
            (axiosPostEndTime - axiosPostStartTime) / 1000
          } seconds`
        );

        console.log("Video annotation updated successfully");
        fetchVideos();
        alert("Video annotation updated successfully");
        onHide();
      } catch (error) {
        console.error("Error updating annotation:", error);
        alert("Failed to update annotation");
      } finally {
        setLoading(false);
        const endTime = Date.now();
        console.log(
          `Total time taken: ${(endTime - startTime) / 1000} seconds`
        );
      }
    }
  };

  const handleReportFalse = async () => {
    if (video.false_report) {
      alert("This video has already been reported as false.");
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to report this annotation as false?"
      )
    ) {
      setLoading(true);

      const apiEndpoint = `${apiUrl}/false_reports`;

      const data = new FormData();
      data.append("model_id", video.id);
      data.append("generated_annotation", video.annotation);

      try {
        await axios.post(apiEndpoint, data);
        fetchVideos();
        alert("Video reported as false successfully");
        onHide();
      } catch (error) {
        console.error("Error reporting false annotation:", error);
        alert("Failed to report false annotation");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setUpdatedAnnotation(video.updated_annotation || "");
    onHide();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "orange";
      case "Updated":
        return "green";
      case "False Report":
        return "red";
      default:
        return "black";
    }
  };

  return (
    <Container>
      <Modal
        dialogClassName="my-modal"
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Annotation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <CirclesWithBar color="#00BFFF" height={100} width={100} />
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12} lg={6}>
                <div style={{ textAlign: "center" }}>
                  <video
                    width={350}
                    height={440}
                    controls
                    style={{ borderRadius: "15px", backgroundColor: "black" }}
                  >
                    <source src={video.presigned_url} type="video/mp4" />
                  </video>
                </div>
              </Col>

              <Col xs={12} md={12} lg={6}>
                <Card className="mt-1">
                  <Card.Body
                    className="p-2"
                    style={{
                      backgroundColor: "#F9FAFC",
                      border: "#667085",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="custom-scrollbar"
                      style={{
                        fontSize: "14px",
                        lineHeight: "28px",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        overflowY: "auto",
                      }}
                    >
                      <div className="info-row">
                        <span className="info-key">Model_ID</span>
                        <span className="info-value">{video.id}</span>
                      </div>
                      <div className="info-row">
                        <span className="info-key">File_Name</span>
                        <span className="info-value">{video.file_name}</span>
                      </div>
                      <div className="info-row">
                        <span className="info-key">Language</span>
                        <span className="info-value">
                          {video.sign_language}
                        </span>
                      </div>
                      <div className="info-row">
                        <span className="info-key">Status</span>
                        <span
                          className="info-value"
                          style={{
                            color: getStatusColor(video.status),
                            marginBottom: "1%",
                          }}
                        >
                          {video.status}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="mt-2 border-0">
                  <Card.Body className="p-2">
                    <div>
                      <Form.Group controlId="generatedAnnotation">
                        <Form.Label
                          style={{
                            color: "#006EFF",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Generated Annotation
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Dont give up"
                          readOnly
                          autoComplete="off"
                          value={video.annotation}
                          style={{
                            fontSize: "14px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="updatedAnnotation"
                        style={{ marginTop: "20px" }}
                      >
                        <Form.Label
                          style={{ color: "#00AD55", fontWeight: "600" }}
                        >
                          Updated Annotation
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Never give up"
                          value={updatedAnnotation}
                          onChange={(e) => setUpdatedAnnotation(e.target.value)}
                          autoComplete="off"
                          style={{
                            fontSize: "14px",
                            color: "#000000",
                            fontWeight: "500",
                          }}
                          disabled={video.status === "False Report"}
                        />
                      </Form.Group>
                      <div
                        style={{
                          marginTop: "15%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          style={{
                            color: "#E22903",
                            backgroundColor: "#fff",
                            width: "40%",
                            border: "1px solid #E22903",
                          }}
                          onClick={handleReportFalse}
                          disabled={video.status === "Updated"}
                        >
                          Report False
                        </Button>
                        <Button
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(33 114 255)",
                            width: "40%",
                            border: "1px solid #4386FB",
                          }}
                          onClick={handleUpdate}
                          disabled={video.status === "False Report"}
                        >
                          {video.status === "Updated" ? "Modify" : "Update"}
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MyVerticallyCenteredModal;
