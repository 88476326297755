import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import "./videoList.css";
import axios from "axios";
import SummaryCards from "./SummaryCards";
import NavigationBar from "./Navbar";

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [totalVideos, setTotalVideos] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [falseReportCount, setFalseReportCount] = useState(0);
  const [filter, setFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 10;

  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = () => {
    const apiEndpoint = `${apiUrl}/videos`;

    axios
      .get(apiEndpoint)
      .then((response) => {
        const data = response.data;
        const updatedVideos = data.map((video) => ({
          ...video,
          status: video.false_report
            ? "False Report"
            : video.updated_annotation
            ? "Updated"
            : "Pending",
          localUpdatedDate: video.updated_annotation_timestamp
            ? convertUTCToLocalDate(video.updated_annotation_timestamp)
            : null,
        }));
        setVideos(updatedVideos);

        const pending = updatedVideos.filter(
          (video) => video.status === "Pending"
        ).length;
        const updated = updatedVideos.filter(
          (video) => video.status === "Updated"
        ).length;
        const falseReport = updatedVideos.filter(
          (video) => video.status === "False Report"
        ).length;

        setPendingCount(pending);
        setUpdatedCount(updated);
        setFalseReportCount(falseReport);
        setTotalVideos(data.length);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  };

  const convertUTCToLocalDate = (utcDateString) => {
    const utcDate = new Date(utcDateString + "Z");
    return utcDate.toLocaleDateString();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "orange";
      case "Updated":
        return "green";
      case "False Report":
        return "#E22903";
      default:
        return "black";
    }
  };

  const getStatusDot = (status) => (
    <span
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: getStatusColor(status),
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "5px",
      }}
    ></span>
  );

  const handleCardClick = (video) => {
    setSelectedVideo(video);
    setModalShow(true);
  };

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const filteredVideos = videos.filter((video) => {
    const matchesFilter = filter === "all" || video.status === filter;
    const matchesSearch =
      video.annotation.toLowerCase().includes(searchQuery) ||
      (video.updated_annotation &&
        video.updated_annotation.toLowerCase().includes(searchQuery));
    return matchesFilter && matchesSearch;
  });

  // Get current videos for pagination
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <NavigationBar
        onFilterChange={setFilter}
        total={totalVideos}
        pending={pendingCount}
        updated={updatedCount}
        falseReport={falseReportCount}
        onSearch={handleSearch}
      />
      <Container
        className="px-3 py-2 pt-1"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <SummaryCards
          total={totalVideos}
          pending={pendingCount}
          updated={updatedCount}
          falseReportCount={falseReportCount}
          onFilterChange={setFilter}
        />

        <Row>
          {currentVideos.map((video) => (
            <Col key={video.id} md={12} lg={6} className="mb-4">
              <Card
                className="video-list-card"
                onClick={() => handleCardClick(video)}
              >
                <Card.Body>
                  <div className="cards-title">
                    <video
                      className="video-player"
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "10px",
                        backgroundColor: "black",
                      }}
                      src={video.presigned_url}
                      controls
                    ></video>
                    <div
                      className="m-3 mt-0"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="m-2" style={{ maxHeight: "50px", overflow: "hidden", textOverflow: "ellipsis !impo", whiteSpace: "wrap" }}>
                        <Card.Title
                          style={{
                            fontSize: "18px",
                            color: "#006EFF",
                            fontWeight: "600",
                          }}
                        >
                          Generated
                        </Card.Title>
                        <Card.Text
                          style={{
                            fontSize: "16px",
                            color: "#081C43",
                            fontWeight: "500",
                          }}
                        >
                          {video.annotation}
                        </Card.Text>
                      </div>
                      {video.updated_annotation && (
                        <div className="m-2" style={{ maxHeight: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          <Card.Title
                            style={{
                              fontSize: "18px",
                              color: "#00AD55",
                              fontWeight: "600",
                            }}
                          >
                            Updated
                          </Card.Title>
                          <Card.Text
                            style={{
                              fontSize: "16px",
                              color: "#081C43",
                              fontWeight: "500",
                            }}
                          >
                            {video.updated_annotation}
                          </Card.Text>
                        </div>
                      )}
                    </div>
                  </div>

                  <Card.Footer className="footer-container">
                    <div className="footer-item" style={{ marginRight: "2%" }}>
                      Reported :
                      <span
                        style={{
                          fontWeight: "400",
                          color: "#081C43",
                          margin: "1%",
                        }}
                      >
                        {new Date(video.upload_time).toLocaleDateString()}
                      </span>
                    </div>
                    <div className="footer-item">
                      Updated :{" "}
                      <span
                        style={{
                          fontWeight: "400",
                          color: "#081C43",
                          margin: "1%",
                        }}
                      >
                        {video.localUpdatedDate ? video.localUpdatedDate : "-"}
                      </span>
                    </div>
                    <div className="footer-item">
                      {getStatusDot(video.status)}
                      <span
                        style={{
                          fontWeight: "400",
                          color: getStatusColor(video.status),
                          margin: "1%",
                        }}
                      >
                        {video.status}
                      </span>
                    </div>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

      <div className="pagination-controls">
          <Button
            variant="primary"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </Button>
          <span className="mx-2">Page {currentPage} of {Math.ceil(filteredVideos.length / videosPerPage)}</span>
          <Button
            variant="primary"
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastVideo >= filteredVideos.length}
          >
            Next
          </Button>
        </div>

        <div className="remaining-count">
          Showing {currentVideos.length} of {filteredVideos.length} videos
        </div>

        {selectedVideo && (
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            video={selectedVideo}
            fetchVideos={fetchVideos}
          />
        )}
      </Container>
    </>
  );
};

export default VideoList;
