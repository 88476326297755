import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import totalimg from "../Assets/total.png";
import pendingimg from "../Assets/pending.png";
import updateimg from "../Assets/update.png";
import falseReportimg from "../Assets/false.png";
import "./summaryCards.css";

const SummaryCards = ({
  total,
  pending,
  updated,
  falseReportCount,
  onFilterChange,
}) => {
  const cards = [
    {
      title: "Total Report",
      count: total,
      image: totalimg,
      filter: "all",
    },
    {
      title: "Pending",
      count: pending,
      image: pendingimg,
      filter: "Pending",
    },
    {
      title: "Updated Annotation",
      count: updated,
      image: updateimg,
      filter: "Updated",
    },
    {
      title: "False Report",
      count: falseReportCount,
      image: falseReportimg,
      filter: "False Report",
    },
  ];

  return (
    <Row className="my-4 d-none d-lg-flex justify-content-center">
      {cards.map((card, index) => (
        <Col key={index} md={3}>
          <Card
            className="summary-card text-center"
            text="white"
            onClick={() => onFilterChange(card.filter)}
            style={{
              backgroundImage: `url(${card.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "110px",
              cursor: "pointer",
            }}
          >
            <Card.Body style={{ textAlign: "left" }}>
              <Card.Title style={{ fontSize: "20px", fontWeight: "600" }}>
                {card.title}
              </Card.Title>
              <Card.Text>
                <h1 style={{ fontWeight: "600" }}>{card.count}</h1>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SummaryCards;
