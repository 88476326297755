import React from "react";
import { Navbar, Nav, NavLink, Form, FormControl } from "react-bootstrap";
import "./videoList.css";

const NavigationBar = ({
  onFilterChange,
  total,
  pending,
  updated,
  falseReport,
  onSearch,
}) => {
  const handleSearchChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-4" fixed-top>
      <Navbar.Brand
        className="m-2 ps-1"
        style={{ color: "#4386FB", fontSize: "28px", fontWeight: "600" }}
      >
        Central Command System
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-between"
        style={{ marginLeft: "42%" }}
      >
        <Nav className="mr-auto d-lg-none">
          <NavLink onClick={() => onFilterChange("all")}>
            Total Report ({total})
          </NavLink>
          <NavLink onClick={() => onFilterChange("Pending")}>
            Pending ({pending})
          </NavLink>
          <NavLink onClick={() => onFilterChange("Updated")}>
            Updated Annotation ({updated})
          </NavLink>
          <NavLink onClick={() => onFilterChange("False Report")}>
            False Report ({falseReport})
          </NavLink>
        </Nav>
        <Form inline className="ml-auto">
          <FormControl
            type="search"
            placeholder="Search"
            className="mr-sm-2 "
            aria-label="Search"
            onChange={handleSearchChange}
            style={{ width: "408px" }}
          />
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
